.toolbar {
    list-style-type: none;
    display: flex;
    justify-content: space-between;
  }
  
  .toolbar li {
    display: inline-block;
    margin-right: 10px;
    padding-bottom: 2px;
  }
  
  .toolbar li a {
    text-decoration: none;
  }
  
  .toolbar li a:hover {
    cursor: pointer;
  }
  