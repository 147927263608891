.accordion {
    width: 100%;
    display: flex;
    justify-content: center;
    min-width: 450px;
    max-width: 750px;
  }
  
  .accordion-item {
    margin-bottom: 15px;
  }
  
  .accordion-toggle {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 10px;
    background-color: #f2f2f2;
    border: none;
    outline: none;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .accordion-toggle:hover {
    background-color: #eaeaea;
  }
  
  .accordion-heading {
    margin: 0;
  }
  
  .accordion-content {
    display: none;
    padding: 10px;
    background-color: #f9f9f9;
    border: 1px solid #ccc;
  }
  
  .accordion-content p,
  .accordion-content ul {
    margin: 0;
  }
  
  .accordion-content p span,
  .accordion-content ul li {
    display: block;
  }
  
  .accordion-content p span {
    font-weight: bold;
  }
  
  .accordion-content ul {
    margin-top: 10px;
    list-style-type: disc;
    padding-left: 20px;
  }
  