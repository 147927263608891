  .bubble-sender {
    position: relative;
    font-family: sans-serif;
    font-size: 14px;
    line-height: 22px;
    max-width:300px;
    background: #2B60FE;
    border-radius: 20px;
    padding: 18px;
    text-align: left;
    color: white;
    display:flex;
    letter-spacing:0.05em;
    align-items: right;
    justify-content: right;
  }

  .bubble-recipient {
    position: relative;
    font-family: sans-serif;
    font-size: 14px;
    line-height: 22px;
    max-width:300px;
    background: #F1F1F1;
    border-radius: 20px;
    padding: 18px;
    text-align: left;
    color: black;
    letter-spacing:0.05em;
    align-items: left;
    justify-content: left;
  }
  
  .bubble-bottom-left:before {
    content: "";
    width: 0px;
    height: 0px;
    position: absolute;
    border-left: 24px solid lightgrey;
    border-right: 12px solid transparent;
    border-top: 12px solid lightgrey;
    border-bottom: 20px solid transparent;
    left: 32px;
    bottom: -24px;
  }

  .text-area {
    width: 100%;
    height: 100px;
  }
  
  @media (max-width: 850px) {
    .text-area {
      min-width: 80%;
    }
  }

  .text-area-default{
    color:grey;
  }

  /* Button Styles */
  .chakra-button {
    display: inline-block;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 0.25rem;
    background-color: #1878F3;
    color: #fff;
    font-weight: 600;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  .chakra-button:hover {
    background-color: #1878F3;
  }