* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  
  header {
    background-color:white;
    color: black;
    font-size: 20px;
    position: relative;
  }

  #fixed-header {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 9999;
  }

  #fixed-header.scrolled {
    background-color: #f2f2f2;
    /* Add any other styles you want when the header is scrolled */
  }
  
  nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
  }
  
  .logo {
    display: inline-block;
    color: white;
  }
  
  .menu {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
  }
  
  .menu li {
    margin-left: 10px; /* Add some spacing between menu items */
  }
  
  .menu li a {
    display: block;
    color: black;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
  }
  
  .icon {
    display: none;
  }

  .hamburger {
    display: none;
    flex-direction: column;
    cursor: pointer;
  }
  
  @media screen and (max-width: 600px) {
    .menu li:not(:last-child){
      display: none;
    }

    .hamburger {
      display: inline;
    }
  }

  @media (min-width: 601px) {
    .desktop-menu {
      justify-content: flex-end;
    }
  
    .mobile-menu {
      display: none;
    }
  }

  .line {
    width: 25px;
    height: 3px;
    background-color:black;
    margin-bottom: 5px;
  }
  
  .close-icon {
    font-size: 45px;
    line-height: 45px;
    /*font-weight: bold;*/
    cursor: pointer;
    /*display: none;*/
  }

  .mobile-menu {
    display: none;
  }
  
  .mobile-menu ul {
    list-style: none;
    padding: 0;
    text-align: right;
  }
  
  .mobile-menu li {
    margin-bottom: 10px;
  }
  
  .mobile-menu a {
    display: inline-block;
    width: 100%;
    text-decoration: none;
    color: #333;
  }
